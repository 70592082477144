"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCode = exports.ApiError = exports.isErrorResponse = exports.ApiClient = void 0;
class ApiClient {
    // plug in either node-fetch or window.fetch depending on use context
    constructor(fetcher, base, token) {
        this.token = token;
        this.base = base;
        this.fetcher = fetcher;
    }
    do(method, path, body) {
        return __awaiter(this, void 0, void 0, function* () {
            let base = this.base;
            let headers = {};
            if (this.token) {
                headers = Object.assign({ Authorization: this.token }, headers);
            }
            let sendingBody = body === null || body === void 0 ? void 0 : body.body;
            if (body && body.kind === "json") {
                headers = Object.assign({ "Content-Type": "application/json" }, headers);
                sendingBody = JSON.stringify(body.body);
            }
            let response = yield this.fetcher.fetch(base + path, {
                headers: headers,
                method: method,
                body: sendingBody,
            });
            console.log(`Response status for ${path}: ${response.status}`);
            if (response.status === 204) {
                return {};
            }
            if (response.status !== 200) {
                let decoded = yield response.json();
                return new ApiError(response.status, decoded);
            }
            return yield response.json();
        });
    }
    get(path) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.do("GET", path);
        });
    }
    post(path, body) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.do("POST", path, body);
        });
    }
    delete(path, body) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.do("DELETE", path, body);
        });
    }
    put(path, body) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.do("PUT", path, body);
        });
    }
    patch(path, body) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.do("PATCH", path, body);
        });
    }
    getCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get("/api/current_user");
        });
    }
    getCurrentUserGuilds() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get("/api/guilds");
        });
    }
    getAllSessions() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get("/api/sessions");
        });
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post("/api/logout");
        });
    }
    deleteSession(token) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.delete("/api/sessions", {
                kind: "json",
                body: {
                    token: token,
                }
            });
        });
    }
    deleteAllSessions() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.delete("/api/sessions/all");
        });
    }
    createApiToken() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.put("/api/sessions");
        });
    }
    confirmLogin(code, state) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post("/api/confirm_login", {
                kind: "json",
                body: {
                    code: code,
                    state: state,
                }
            });
        });
    }
    getUserPremiumSlots() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get("/api/premium_slots");
        });
    }
    updatePremiumSlotGuild(slotId, guildId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/premium_slots/${slotId}/update_guild`, {
                kind: "json",
                body: { guild_id: guildId }
            });
        });
    }
    getFullDiscordGuild(guildId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/guilds/${guildId}/full_guild`);
        });
    }
    getAllScripts(guildId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/guilds/${guildId}/scripts`);
        });
    }
    getAllScriptsWithPlugins(guildId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/guilds/${guildId}/scripts_with_plugins`);
        });
    }
    createScript(guildId, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.put(`/api/guilds/${guildId}/scripts`, {
                kind: "json",
                body: data
            });
        });
    }
    updateScript(guildId, id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.patch(`/api/guilds/${guildId}/scripts/${id}`, {
                kind: "json",
                body: data
            });
        });
    }
    validateScript(guildId, id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/guilds/${guildId}/scripts/${id}/validate_settings`, {
                kind: "json",
                body: data
            });
        });
    }
    delScript(guildId, id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.delete(`/api/guilds/${guildId}/scripts/${id}`);
        });
    }
    reloadGuildVm(guildId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/guilds/${guildId}/reload_vm`);
        });
    }
    getGuildMetaConfig(guildId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/guilds/${guildId}/settings`);
        });
    }
    getNews() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/news`);
        });
    }
    getGuildPremiumSlots(guildId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/guilds/${guildId}/premium_slots`);
        });
    }
    getPublishedPublicPlugins() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/plugins`);
        });
    }
    getCurrentUserPlugins() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/user/plugins`);
        });
    }
    getPlugin(scriptId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.get(`/api/plugins/${scriptId}`);
        });
    }
    createPlugin(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.put(`/api/user/plugins`, {
                kind: "json",
                body: params
            });
        });
    }
    updatePluginMeta(pluginId, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.patch(`/api/user/plugins/${pluginId}`, {
                kind: "json",
                body: params
            });
        });
    }
    updateScriptPluginDevVersion(pluginId, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.patch(`/api/user/plugins/${pluginId}/dev_version`, {
                kind: "json",
                body: { new_source: params.source }
            });
        });
    }
    publishScriptPluginVersion(pluginId, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/user/plugins/${pluginId}/publish_script_version`, {
                kind: "json",
                body: { new_source: params.source }
            });
        });
    }
    addPluginToGuild(pluginId, guildId, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/guilds/${guildId}/add_plugin`, {
                kind: "json",
                body: {
                    plugin_id: pluginId,
                    auto_update: params.auto_update,
                }
            });
        });
    }
    updateScriptPlugin(guildId, scriptId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/guilds/${guildId}/scripts/${scriptId}/update_plugin`);
        });
    }
    // data should be a FormData, but this wrapper don't have DOM libs
    addPluginImage(pluginId, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/user/plugins/${pluginId}/images`, {
                kind: "custom",
                body: data
            });
        });
    }
    deletePluginImage(pluginId, imageId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.delete(`/api/user/plugins/${pluginId}/images/${imageId}`);
        });
    }
    createStripeCheckoutSession(tier) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/stripe/create_checkout_session`, {
                kind: "json",
                body: {
                    tier,
                }
            });
        });
    }
    createStripeCustomerPortalSession() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.post(`/api/stripe/customer_portal`);
        });
    }
}
exports.ApiClient = ApiClient;
function isErrorResponse(resp) {
    return resp instanceof ApiError;
}
exports.isErrorResponse = isErrorResponse;
class ApiError {
    constructor(resp_code, response) {
        this.is_error = true;
        this.resp_code = resp_code;
        this.response = response;
    }
    getFieldError(field) {
        var _a, _b, _c;
        if (((_a = this.response) === null || _a === void 0 ? void 0 : _a.code) === 4 && ((_b = this.response) === null || _b === void 0 ? void 0 : _b.extra_data)) {
            return (_c = this.response.extra_data.find((v) => v.field === field)) === null || _c === void 0 ? void 0 : _c.msg;
        }
        return undefined;
    }
}
exports.ApiError = ApiError;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["SessionExpired"] = 1] = "SessionExpired";
    ErrorCode[ErrorCode["BadCsrfToken"] = 2] = "BadCsrfToken";
    ErrorCode[ErrorCode["InternalError"] = 3] = "InternalError";
    ErrorCode[ErrorCode["ValidationFailed"] = 4] = "ValidationFailed";
    ErrorCode[ErrorCode["NoActiveGuild"] = 5] = "NoActiveGuild";
    ErrorCode[ErrorCode["NotGuildAdmin"] = 6] = "NotGuildAdmin";
    ErrorCode[ErrorCode["NoAccessToPlugin"] = 7] = "NoAccessToPlugin";
    ErrorCode[ErrorCode["UserPluginLimitReached"] = 8] = "UserPluginLimitReached";
    ErrorCode[ErrorCode["PluginNotFound"] = 9] = "PluginNotFound";
    ErrorCode[ErrorCode["GuildAlreadyHasPlugin"] = 10] = "GuildAlreadyHasPlugin";
})(ErrorCode || (exports.ErrorCode = ErrorCode = {}));
