"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscordNumberedChannelTypes = void 0;
var DiscordNumberedChannelTypes;
(function (DiscordNumberedChannelTypes) {
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["GuildText"] = 0] = "GuildText";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["Private"] = 1] = "Private";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["GuildVoice"] = 2] = "GuildVoice";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["Group"] = 3] = "Group";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["GuildCategory"] = 4] = "GuildCategory";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["GuildAnnouncement"] = 5] = "GuildAnnouncement";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["AnnouncementThread"] = 10] = "AnnouncementThread";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["PublicThread"] = 11] = "PublicThread";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["PrivateThread"] = 12] = "PrivateThread";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["GuildStageVoice"] = 13] = "GuildStageVoice";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["GuildDirectory"] = 14] = "GuildDirectory";
    DiscordNumberedChannelTypes[DiscordNumberedChannelTypes["GuildForum"] = 15] = "GuildForum";
})(DiscordNumberedChannelTypes || (exports.DiscordNumberedChannelTypes = DiscordNumberedChannelTypes = {}));
